import React from 'react'
import { navigate } from '@reach/router'

const SectionBoxReady = ({ title, content, style, className }) => {
     const onClickGetStarted = () => {
          navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          // window.location.replace(`${process.env.GATSBY_APP_PATH}/register`)
     }

     return (
          <section style={{ marginBottom: style }}>
               <div className={`section-ready ${className}`}>
                    <div className="container">
                         <div className=" section-ready-box">
                              <div className="title">
                                   <h3>{title}</h3>
                                   <p>{content}</p>
                              </div>
                              <div className="button-wrapper">
                                   <button className="button" to={'#'} style={{ fontSize: '15px' }} onClick={() => onClickGetStarted()}>
                                        Get started
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default SectionBoxReady
