import React from 'react'

const SectionBoxCardText = ({ data, className }) => {
     return (
          <section className={`centralized-data ${className}`}>
               <div className="centralized-data-card">
                    <div className="container">
                         {data.map(item => {
                              return (
                                   <div className="centralized-data-card-box">
                                        <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                        <p>{item.desc}</p>
                                        {item.otherDesc && <p className="mt-2">{item.otherDesc}</p>}
                                        {item.learnMore && (
                                             <div className="learn-more">
                                                  <a href={item.url}>{item.learnMore}</a>
                                             </div>
                                        )}
                                   </div>
                              )
                         })}
                    </div>
               </div>
          </section>
     )
}

export default SectionBoxCardText
