import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const Consent = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Centralize Consent & Privacy Management',
          description:
               "Reduce the number of tools you use and remain compliant with regulations like GDPRA & CCPA using ListenLayer's Consent Management Platform.",
          // substring:
          //      "Reduce the number of tools you use and remain compliant with regulations like GDPRA & CCPA using ListenLayer's Consent Management Platform.",
     }
     const headerIntroCard = {
          description: `<span>Consent with privacy and cookies laws is complicated. ListenLayer makes it easy by centralizing your compliance at the single source of truth.</span>`,
          fName: 'Zachary W. Randall',
          fPosition: 'Founder & CEO',
          imageURL: '/images/hero-image.png',
     }

     const dataCartText = [
          {
               title: 'Cookieless',
               desc:
                    'ListenLayer can operate 100% free of cookies, while still providing the benefits without the risks. This simplifies our compliance as you begin to centralize your data and privacy strategy. ',
          },
          {
               title: 'Compliant, Out of the Box',
               desc:
                    'ListenLayer is compliant with GDPR, CCPA, and other privacy regulations out of the box. Regional rules define how users are identified and how consent can be changed within their session.',
          },
          {
               title: 'Replace Your CMP',
               desc:
                    "Simplify your tech stack with ListenLayer's optional Consent Management Platform, or augment the CMP you already use with our integrations. Now, you can manage consent requirements at the source or truth rather than implementing and managing a separate tool like OneTrust, Cookiebot, or UserCentrics.",
          },
          {
               title: 'Automate or Customize',
               desc:
                    "Rely on ListenLayer's prebuilt consent rules or apply your own customizations. Compliance with your region and internal policies is ultimately your responsibility, but we can help architect the correct settings!",
          },
          {
               title: 'Peace of Mind',
               desc:
                    "You can't have peace of mind when consent has to be managed across multiple platforms. Centralize into a single source that controls all of your website data.",
          },
          {
               title: 'Security-first Architecture',
               desc: 'We take the security of your data seriously, with full encryption, access controls, and proactive measures.',
               otherDesc: 'ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.',
          },
          {
               title: 'HIPAA Compliant Analytics & Conversion Tracking',
               desc:
                    'ListenLayer offers a powerful, HIPAA compliant analytics solution that allows you to track and report on website users, and event distribute conversion data to marketing platforms, while remaining compliant. Our powerful server-side tagging enables the control required.',
               learnMore: 'Learn more >',
               url: '/platform/hippa/',
          },
     ]

     const headline = 'Frequently Asked Questions'

     const faqs = [
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes, but it's optional. ListenLayer has a full CMP (consent management platform) built in. Whenever your ready, turn off your old tool and enable ListenLayer's CMPs, or reach out to our support for help.</p>\n",
                    faqQuestion: 'Can ListenLayer replace my current cookie popup tool?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Yes. ListenLayer integrates with a number of CMPs (consent management platforms), so you can continue to use your existing tool.</p>\n',
                    faqQuestion: 'Can I continue to use my current cookie/consent popup tool?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes, we believe our platform is fully compliant. By default, and even if you do not enable ListenLayer's consent management platform, ListenLayer leverages regional consent rules to govern how users and people are identified and what data is collected. This means you can use ListenLayer worldwide, and remain in compliance with local regulations. Of course, these laws are always changing and we encourage you to consult your own counsel regarding your implementation.</p>\n",
                    faqQuestion: 'Is ListenLayer GDPR and CCPA compliant?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Consent regulations govern how you identify users on your website, whether you can place cookies on their devices, and where you send the data. Marketers tend to have a very fragmented data strategy, with conversion tracking and data collection independently configured across an average of 6 platforms.</p>\n' +
                         "<p className='pt-4'>ListenLayer centralizes how you define conversions and data collection. It makes sense to define how you identify and use data, based on regional consent laws, in the single source of collection and truth. You can still distribute data to other platforms (Google Ads, Facebook Ads, Hubspot, Google Analytics), but within the context of your accurate, centralized consent settings.</p>\n",
                    faqQuestion: "What is the benefit of enabling ListenLayer's Consent Management Platform?",
               },
               type: 'WpCptFaq',
          },
     ]

     const buttonText = ''

     const pageName = 'Consent Management Platform'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} />
               <section className="consent-text">
                    <div class="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large">
                              <h3 class="text-center">Consent Management Platform</h3>
                              <div class="bodyCopy">
                                   <p>
                                        On top of an already-complicated marketing stack, most organizations employ a third party "cookie blocking"
                                        tool like OneTrust, Cookiebot or UserCentrics to control the flow of data. But compliance is not just about
                                        blocking cookies! And managing yet another tool, simply introduces compliance risk.
                                   </p>
                                   <p>
                                        ListenLayer centralizes your entire marketing data strategy, and with it, the ability to maintain privacy and
                                        compliance standards to address the large number of privacy, consent, and "cookie laws" on the books.
                                   </p>
                                   <p>
                                        We even provide one of the only BAA covered, HIPAA compliant marketing analytics and conversion tracking
                                        solutions, allowing you to control the data you share with advertising and analytics platforms like Google
                                        Analytics 4, Facebook, and Google Ads.
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>

               <SectionBoxCardText data={dataCartText} />

               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
               />

               <section className="enhance-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={faqs} buttonText={buttonText} headline={headline} />
                    </div>
               </section>
          </Layout>
     )
}

export default Consent
